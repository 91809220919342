import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

/** LOGIN */
const Login = () => import("@/pages/Login");
const NotFound = () => import("@/pages/Page404");

/** PROFILE */
const Profile = () => import("@/views/profile/index.vue");

/** ACCESS */
const Permission = () => import("@/views/access/permission.vue");
const Roles = () => import("@/views/access/roles.vue");
const Users = () => import("@/views/access/users.vue");
const AssignedPermission = () =>
  import("@/views/access/assignedpermission.vue");

/** MANTENIMIENTOS */
const About = () => import("@/views/maintenance/about.vue");
const Supplier = () => import("@/views/maintenance/supplier.vue");
const Certification = () => import("@/views/maintenance/certifications.vue");
const SupplierExpense = () =>
  import("@/views/maintenance/supplierexpenses.vue");
const Clients = () => import("@/views/maintenance/clients.vue");
const ProductEntries = () => import("@/views/maintenance/productentries.vue");
const Transport = () => import("@/views/maintenance/transport.vue");
const Destination = () => import("@/views/maintenance/destination.vue");
const Provider = () => import("@/views/maintenance/provider.vue");
const Box = () => import("@/views/maintenance/box.vue");

/** PROCESOS */
const Estimate = () => import("@/views/proccess/estimate.vue");
const Acopio = () => import("@/views/proccess/acopio.vue");
const AcopioReg = () => import("@/views/proccess/acopio_reg.vue");
const AcopioEdit = () => import("@/views/proccess/acopio_edit.vue");
const Sendacopio = () => import("@/views/proccess/sendacopio.vue");
const SendacopioReg = () => import("@/views/proccess/sendacopio_reg.vue");
const Acopionosocio = () => import("@/views/proccess/acopio_nosocio.vue");
const AcopionosocioEdit = () =>
  import("@/views/proccess/acopio_nosocio_edit.vue");

/** EMITIR DOCUMENTOS */
const SaleDocument = () => import("@/views/sale/document.vue");
const PurhaseDocument = () => import("@/views/purchase/document.vue");

/** CAJA */
const Bead = () => import("@/views/box/bead.vue");

/** REPORTES */
const RptAcopio = () => import("@/views/reports/rptacopio.vue");
const RptLote = () => import('@/views/reports/rptlote.vue')

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/login",
      name: "",
      component: {
        render(c) {
          return c("router-view");
        }, //componente
      },
      children: [
        {
          path: "login",
          name: "Login",
          component: Login,
        },
      ],
    },
    {
      path: "/perfil",
      redirect: "/perfil",
      name: "Mi perfil",
      component: TheContainer, // montar componente
      children: [
        {
          path: "/",
          name: "Mis datos",
          component: Profile,
        },
      ],
    },
    {
      path: "/home",
      redirect: "/home",
      name: "Escritorio",
      component: TheContainer, // montar componente
      children: [
        {
          path: "/",
          name: "Inicio",
          component: Dashboard,
        },
      ],
    },
    {
      path: "/accesos",
      redirect: "/accesos/permisos",
      name: "Accesos ",
      component: TheContainer,
      children: [
        {
          path: "/accesos/permisos",
          name: "Permisos de usuario",
          component: Permission,
        },
        {
          path: "/accesos/tipos",
          name: "Roles de usuario",
          component: Roles,
        },
        {
          path: "/accesos/usuarios",
          name: "Usuarios",
          component: Users,
        },
        {
          path: "/accesos/permisos_asignados",
          name: "Permisos Asignados",
          component: AssignedPermission,
        },
      ],
    },
    {
      path: "/maintenance",
      redirect: "/maintenance/supplier",
      name: "Mantenimientos ",
      component: TheContainer,
      children: [
        {
          path: "/maintenance/about",
          name: "Empresa",
          component: About,
        },
        {
          path: "/maintenance/supplier",
          name: "Proveedores",
          component: Supplier,
        },
        {
          path: "/maintenance/certification",
          name: "Certificacion",
          component: Certification,
        },
        {
          path: "/maintenance/supplierexpenses",
          name: "Prove Gastos",
          component: SupplierExpense,
        },
        {
          path: "/maintenance/provider",
          name: "Proveedor natural",
          component: Provider,
        },
        {
          path: "/maintenance/clients",
          name: "Clientes",
          component: Clients,
        },
        {
          path: "/maintenance/productentries",
          name: "Entradas de productos",
          component: ProductEntries,
        },
        {
          path: "/maintenance/transport",
          name: "Transporte",
          component: Transport,
        },
        {
          path: "/maintenance/destination",
          name: "Destino",
          component: Destination,
        },
        {
          path: "/maintenance/box",
          name: "Caja",
          component: Box,
        },
      ],
    },
    {
      path: "/proccess",
      redirect: "/proccess/estimate",
      name: "Procesos ",
      component: TheContainer,
      children: [
        {
          path: "/proccess/estimate",
          name: "Producción estimada",
          component: Estimate,
        },
        {
          path: "/proccess/acopio",
          name: "Acopio",
          component: Acopio,
        },
        {
          path: "/proccess/acopio/register",
          name: "Registro de Acopio",
          component: AcopioReg,
        },
        {
          path: "/proccess/acopio/edit/:id",
          name: "Editar Acopio",
          component: AcopioEdit,
        },
        {
          path: "/send/acopio",
          name: "Salida de acopio",
          component: Sendacopio,
        },
        {
          path: "/send/acopio/register",
          name: "Registro de salida acopio",
          component: SendacopioReg,
        },
        {
          path: "/proccess/provideracopio",
          name: "Registro de acopio de no socio",
          component: Acopionosocio,
        },
        {
          path: "/proccess/provideracopio/edit/:id",
          name: "Editar acopio de no socio",
          component: AcopionosocioEdit,
        },
      ],
    },
    /** VENTAS */
    {
      path: "/sale",
      redirect: "/sale/document",
      name: "Emitir documentos",
      component: TheContainer,
      children: [
        {
          path: "/sale/document",
          name: "Ventas",
          component: SaleDocument,
        },
        {
          path: "/purchase/document",
          name: "Compras",
          component: PurhaseDocument,
        },
      ],
    },
    /** CAJA */
    {
      path: "/proccess/bead",
      redirect: "/proccess/bead",
      name: "Caja",
      component: TheContainer, // montar componente
      children: [
        {
          path: "/",
          name: "Caja",
          component: Bead,
        },
      ],
    },
    {
      path: "/reports",
      redirect: "/reports/rptacopio",
      name: "Reportes ",
      component: TheContainer,
      children: [
        {
          path: "/reports/rptacopio",
          name: "Acopio",
          component: RptAcopio,
        },
        {
          path: "/reports/rptlote",
          name: "Lote",
          component: RptLote,
        },
      ],
    },
    { path: "*", component: NotFound },
  ],
});
